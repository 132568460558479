/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef,useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, ChipIcon, XIcon,	ShieldCheckIcon, DatabaseIcon, CurrencyDollarIcon, SpeakerphoneIcon } from '@heroicons/react/outline'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar';

const features = [
  {
    name: 'High-End Hardware',
    description:
      'We use AMD and Intel CPUs, DDR4 ECC memory, and SSD to ensure the stability of your high-performance server.  ',
    icon: ChipIcon,
  },
  {
    name: 'Easy to Use',
    description:
      'We do not make our dashboard too complex for you to use. Instead, we offer an panel that is beautifully designed, with many features.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'DDoS Protection',
    description:
      "I can't think of anything to put here, daily downtimes, daily DDoS, I just can't think of anything. ",
    icon: ShieldCheckIcon,
  },
  {
    name: 'Customer Support',
    description:
      "There is a ticket system as well as a community support channel in our Discord, where you can look for all related information.",
    icon: DatabaseIcon,
  },
]

const navigation = [
  { name: 'Home', href: '#', current: false },
  { name: 'Status', href: '#', current: false },
  { name: 'Discord', href: 'https://dsc.gg/ultimatesrv', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function Home() {
  const ref = useRef(null);
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);

  const handleLoad = () => {
    ref.current.continuousStart();
      setTimeout(() => {
        setLoading(!loading);
        window.location.href = "https://dashboard.ultimatesrv.com";
        ref.current.complete();
      }, 1000);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: indigo;
  `;
  
  return (
    <>
      <LoadingBar color="#ffffff" ref={ref} />
      {loading && 
        <div className="sweet-loading absolute top-0 right-0 p-5">
            <ClipLoader color="#ffffff" css={override} size={50} />
        </div>
      }
      
      <div className="bg-indigo-600">
        <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-indigo-800">
                <SpeakerphoneIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span className="md:hidden">This messages is for mobile.</span>
                <span className="hidden md:inline">Make this whatever you want, just tell me.</span>
              </p>
            </div>
            <div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
            </div>
            <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
              >
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="min-h-full">
        
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }) => (
            <>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <img
                        className="h-8 w-8"
                        href="/"
                        src="https://media.discordapp.net/attachments/904385467359842345/954523957606899792/unnamed.png"
                        alt="UltimateSRV Logo"
                      />
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item) => (
                          
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      <button
                        type="button"
                        className="bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      >
                      </button>
                      {/* Profile dropdown */}
                      <Menu as="div" className="ml-3 relative">
                        <div>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                    </div>
                    <div className="ml-3">
                    </div>
                    <button
                      type="button"
                      className="ml-auto bg-gray-800 flex-shrink-0 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    >
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <main>
          <div className="dark:bg-slate-900 mx-auto py-30 sm:px-6 lg:px-8 ">
            {/* Introduction */}
            <div className="px-4 py-6 sm:px-0">
              <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                <div className="sm:text-center lg:text-left">
                  <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                    <span className="dark:text-white block xl:inline">Free and Reliable </span>{' '}
                    <span className="block text-indigo-500 xl:inline">Hosting</span>
                  </h1>
                  <p className="text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                      It's understandable that you want to own a Minecraft Server, but you can't afford one. Let UltimateSRV provide you with a free server, simply sign up!
                  </p>
                  <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow">
                        <button onClick={handleLoad} className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                            Get Started
                        </button>
                    </div>
                    <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a
                        href="#"
                        className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-gray-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10"
                      >
                        Discover our Plans
                      </a>
                    </div>
                  </div>
                </div>
              </main>
            </div>
            {/* Features */}
            <div className="px-4 py-6 sm:px-0">
              <div className="py-12 dark:bg-slate-900">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="lg:text-center">
                    <h2 className="text-base text-indigo-500 font-semibold tracking-wide uppercase">Features that you will love</h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                      A better way to host your server
                    </p>
                    <p className="mt-4 text-xl text-gray-500 lg:mx-auto">
                        We will take care of the rest while you concentrate on building your community.
                    </p>
                  </div>
                  <div className="mt-10">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                      {features.map((feature) => (
                        <div key={feature.name} className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 dark:text-white">
                              <feature.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium dark:text-white">{feature.name}</p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            {/* FAQs */}
            <div className="px-4 py-6 sm:px-0">
              <div className="py-12 dark:bg-slate-900">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="lg:text-center">
                    <h2 className="text-base text-indigo-500 font-semibold tracking-wide uppercase">FAQs</h2>
                    <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                      We answered some of your questions
                    </p>
                    <br></br>
                    <div id="accordion-collapse" data-accordion="collapse">
                      <h2 id="accordion-collapse-heading-4">
                        <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-4" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                          <span>Can I use this for Discord Bot Hosting?</span>
                          <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                      </h2>
                      <div id="accordion-collapse-body-4" class="hidden" aria-labelledby="accordion-collapse-heading-4">
                        <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                          <p class="mb-2 text-gray-500 dark:text-gray-400">Yes, you can host a Discord Bot for free.</p>
                        </div>
                      </div>


                      <h2 id="accordion-collapse-heading-5">
                        <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-5" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                          <span>How do I op myself?</span>
                          <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                      </h2>
                      <div id="accordion-collapse-body-5" class="hidden" aria-labelledby="accordion-collapse-heading-5">
                        <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                          <p class="mb-2 text-gray-500 dark:text-gray-400">Go-to the Pterodactyl Panel and select your server. Then inside of the console tab, type /op (yourusername).</p>
                        </div>
                      </div>

                      <h2 id="accordion-collapse-heading-6">
                        <button type="button" class="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-6" aria-expanded="false" aria-controls="accordion-collapse-body-3">
                          <span>Can I use a custom .jar?</span>
                          <svg data-accordion-icon class="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </button>
                      </h2>
                      <div id="accordion-collapse-body-6" class="hidden" aria-labelledby="accordion-collapse-heading-6">
                        <div class="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
                          <p class="mb-2 text-gray-500 dark:text-gray-400">You can use any custom server.jar so long as it does not require an installation.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Get Started Now */}
            <div className="px-4 py-6 sm:px-0">
              <div className="dark:bg-slate-900 bg-gray-50">
                <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                    <span className="block dark:text-white">Ready to host your server?</span>
                    <span className="block text-indigo-600">Create your free account today.</span>
                  </h2>
                  <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div className="inline-flex rounded-md shadow">
                      <button
                        onClick={handleLoad} 
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                      >
                        Get Started
                      </button>
                    </div>
                    <div className="ml-3 inline-flex rounded-md shadow">
                      <a
                        href="#"
                        className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
                      >
                        Contact Us
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
           <footer class="p-4 bg-white sm:p-6 dark:bg-gray-800">
              <div class="max-w-7xl mx-auto py-12 px-6 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                  <div class="mb-6 md:mb-0">
                      <a href="https://ultimatesrv.com" class="flex-shrink-0 flex items-center">
                          <img src="https://media.discordapp.net/attachments/904385467359842345/954523957606899792/unnamed.png" class="mr-3 h-8" alt="UltimateSRV Logo" />
                          <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">UltimateSRV</span>
                      </a>
                  </div>
                  <br></br>
                  <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                      <div>
                          <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Links</h2>
                          <ul class="text-gray-600 dark:text-gray-400">
                              <li class="mb-4">
                                  <a href="https://dashboard.ultimatesrv.com" class="hover:underline">Dashboard</a>
                              </li>
                              <li>
                                  <a href="https://console.ultimatesrv.com" class="hover:underline">Game Panel</a>
                              </li>
                          </ul>
                      </div>
                      <div>
                          <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                          <ul class="text-gray-600 dark:text-gray-400">
                              <li class="mb-4">
                                  <a href="#" class="hover:underline">Privacy Policy</a>
                              </li>
                              <li>
                                  <a href="#" class="hover:underline">Terms &amp; Conditions</a>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
              <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
              <span class="block text-sm text-gray-500 sm:text-center dark:text-gray-400">Copyright © 2022 <a href="https://ultimatesrv.com" class="hover:underline">UltimateSRV</a>. All Rights Reserved.
              </span>
          </footer> 
        </main>
      </div>
    </>
  )
}
